var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('h3',{staticClass:"title-header noprint mt-5"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.evidencia_de_desempeño", true, "Evidencias de Desempeño" ))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'manual.evidencia_de_desempeño',"allows_crud":_vm.allows_crud}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('ButtonMedium',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_procedureevidence'),expression:"'mesh.add_procedureevidence'"}],attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
            'manual.evidencia_de_desempeño',
            false,
            'Evidencia de Desempeño'
          )}`,"text_button":`Agregar`,"click_button":() => _vm.$bvModal.show(`new-procedure-evidence-modal`),"icon":'plus',"variant":'primary'}}):_vm._e()],1)]),_c('GenericBTable',{attrs:{"items":_vm.procedure_evidences,"filterCustom":_vm.filterCustom,"fields":_vm.procedureEvidenceFields,"filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":'procedure_evidences',"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(description)",fn:function(row){return [_c('div',{staticClass:"rich-text-content",domProps:{"innerHTML":_vm._s(row.item.description)}})]}},(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_procedureevidence'),expression:"'mesh.change_procedureevidence'"}],class:``,attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
            'manual.evidencia_de_desempeño',
            false,
            'Evidencia de Desempeño'
          )}`,"click_button":() =>
              _vm.$bvModal.show(`edit-procedure-evidence-modal-${row.item.id}`),"icon":'square'}}),_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('mesh.delete_procedureevidence'),expression:"'mesh.delete_procedureevidence'"}],attrs:{"click_button":() => _vm.askForDeleteProcedureEvidence(row.item.id),"tooltip_text":`Eliminar ${_vm.$getVisibleNames(
            'manual.evidencia_de_desempeño',
            false,
            'Evidencia de Desempeño'
          )}`,"icon":'trash'}}),_c('b-modal',{attrs:{"id":`edit-procedure-evidence-modal-${row.item.id}`,"title":"Editar Evidencia de Desempeño","size":"md","hide-footer":""}},[_c('ProcedureEvidenceForm',{attrs:{"ProcedureEvidence":row.item,"institution_id":row.item.school,"show_title":false},on:{"updated":_vm.slotUpdatedProcedureEvidence}})],1)]}}:null],null,true)})],1),_c('b-modal',{attrs:{"id":`new-procedure-evidence-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'manual.evidencia_de_desempeño',
      false,
      'Evidencia de Desempeño'
    )}`,"size":"lg"}},[_c('ProcedureEvidenceForm',{attrs:{"institution_id":this.user.school},on:{"created":_vm.slotCreatedProcedureEvidence}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }